<template>
  <a-modal :maskClosable="false" width="550px" v-model="open" title="裁剪头像" @cancel="cancel" @ok="handleOk">
    <div @mouseover="topCenterMouseover" class="cropper-content">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          mode="100% auto"
          :height="option.height"
          :fixedNumber="option.fixedNumber"
          :fixed="true"
          :centerBox="true"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          @realTime="realTime"
        ></vue-cropper>
      </div>
      <div class="show-preview" :style="{ height: previews.h + 'px', overflow: 'hidden', 'margin-left': '40px' }">
        <div class="preview" :style="previews.div">
          <img :src="previews.url" :style="previews.img" />
        </div>
      </div>
    </div>

    <div class="footer-btn" @mouseover="topCenterMouseover">
      <div class="scope-btn">
        <!-- <label class="btn" for="uploads">更换图片</label> -->
        <input
          type="file"
          id="uploads"
          style="position:absolute; clip:rect(0 0 0 0);"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          @change="uploadImg($event, 1)"
        />

        <a-button shape="circle" icon="plus" @click="changeScale(1)"></a-button>
        <a-button shape="circle" icon="minus" @click="changeScale(-1)"></a-button>
        <a-button shape="circle" icon="undo" @click="rotateLeft"></a-button>
        <a-button shape="circle" icon="redo" @click="rotateRight"></a-button>
      </div>
      <!-- <div class="upload-btn">
         <button>下载</button>
      </div> -->
    </div>
  </a-modal>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  props: {},
  components: {
    VueCropper
  },
  data() {
    return {
      open: false,
      imageUrl: '',
      crap: false,
      previews: {
        img: ''
      },
      option: {
        img: '',
        size: 1,
        full: false, //输出原图比例截图 props名ful
        outputType: 'png',
        canMove: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 120,
        autoCropHeight: 120,
        fixedBox: false,
        height: true,
        fixedNumber: [2, 2]
      },
      downImg: '#',
      fileName: ''
    }
  },
  computed: {},
  created() {},
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      var test = window.open('about:blank')
      test.document.body.innerHTML = '图片生成中..'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          var img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    // 实时预览函数
    realTime(data) {
      /*       let num =data.img.transform.split(',')[1].replace('px','')-0+109+'px'
      data.img.transform=data.img.transform.split(',')[0]+','+num+','+data.img.transform.split(',')[2] */
      this.previews = data
    },
    openModal(url) {
      this.open = true
      this.option.img = url
      this.fileName = this.getFilename(url)
    },
    getFilename(fileUrl) {
      if (!fileUrl) return 'UNKNOWN'
      let url = decodeURIComponent(fileUrl)
      // 如果不是网络url,直接使用
      if (!url.includes('/')) return url
      // 截取url最后一个"/"后面"?"前面的
      url = url.trim().substring(url.lastIndexOf('/') + 1)
      if (url.includes('?')) {
        url = url.substring(0, url.indexOf('?'))
      }
      return url
    },
    handleOk() {
      this.$refs.cropper.getCropBlob(data => {
        // do something
        let file = new window.File([data], this.fileName, { type: this.option.outputType })
        this.$emit('upImg', file)
        this.open = false
      })
    },
    cancel() {}
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-wrap {
  z-index: 2040 !important;
}
.preview {
  img {
    position: relative;
    top: -15px;
    left: -4px;
  }
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  .cropper {
    width: 200px;
    height: 200px;
  }
  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    .preview {
      border: 1px solid #cccccc;
      background: #cccccc;
      position: relative;
      border-radius: 100%;
      overflow: hidden;
      img {
        position: relative;
        top: 0px;
      }
      /*       height: 120px !important;
      width: 120px !important; */
    }
  }
}
.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  .scope-btn {
    width: 200px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #67c23a;
    border-color: #67c23a;
  }
}
/deep/ .ant-modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
